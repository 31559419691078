import { Plugin } from 'vue';
import { ValidationPluginOptions, ValidationRuleDictionary } from './types';
import { VALIDATION_I18N_PATH } from './config';
import { all } from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';
import { customRules } from './custom-rules';
import { validationMessageGenerator } from './validation-message-generator';

function ruleLoader<T extends ValidationRuleDictionary>(ruleDictionary: T) {
  Object.entries(ruleDictionary).forEach(([name, rule]) => {
    defineRule(name, rule);
  });
}

export const ValidationPlugin: Plugin<ValidationPluginOptions> = (
  app,
  { i18n },
) => {
  ruleLoader(all);
  ruleLoader(customRules);
  configure({
    generateMessage: validationMessageGenerator(i18n.t, VALIDATION_I18N_PATH),
  });
};
