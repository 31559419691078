<script lang="ts" setup>
  import { toRef, toRefs } from 'vue';
  import DatePicker from './DatePicker.vue';
  import { useField } from 'vee-validate';
  import { InputWidthVariant } from '../InputWrapper/';
  import { DatePickerProps } from './DatePicker.types.ts';

  export type DatePickerFieldProps = Omit<DatePickerProps, 'modelValue'> & {
    rules?: (value: Date) => string | boolean;
  };

  const props = withDefaults(defineProps<DatePickerFieldProps>(), {
    rules: undefined,
    width: InputWidthVariant.FULL,
  });

  const {
    name: inputName,
    label,
    rules,
    ...rest
  } = toRefs<DatePickerFieldProps>(props);
  // workaround to keep reactivity on rest value from toRefs
  const inputProps = toRef(rest);

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
  } = useField<Date>(inputName!.value!, rules?.value, {
    label: label?.value,
    initialValue: props.startDate ? new Date(props.startDate) : undefined,
  });
</script>

<template>
  <DatePicker
    v-bind="inputProps"
    :error="errorMessage as string"
    :label="label"
    :meta="meta"
    :loading="loading"
    :modelValue="inputValue"
    :name="name"
    :placeholder="placeholder"
    @blur="handleBlur"
    @update:modelValue="handleChange"
  >
    <template #iconRight>
      <slot name="iconRight"></slot>
    </template>
  </DatePicker>
</template>
