import { WelfarePlan, useAuthStore } from '@tundr/auth';
import { creditsRoutesDictionary } from './credits-routes.dictionary';

export const creditsRoutesConfig = {
  ...creditsRoutesDictionary.credits.root,
  redirect: { name: creditsRoutesDictionary.credits.overview.name },
  beforeEnter: () => {
    const authStore = useAuthStore();
    return authStore.currentWelfarePlan === WelfarePlan.STANDARD;
  },
  children: [
    {
      ...creditsRoutesDictionary.credits.overview,
      component: () => import('../pages/CreditsOverview/CreditsOverview.vue'),
    },
    {
      ...creditsRoutesDictionary.credits.chooseAssignMethod,
      component: () =>
        import('../pages/ChooseAssignmentMethod/ChooseAssignmentMethod.vue'),
    },
    {
      ...creditsRoutesDictionary.credits.standardAssignmentFlow,
      component: () =>
        import('../pages/CreditsAssignment/CreditsAssignmentStepper.vue'),
    },
    {
      ...creditsRoutesDictionary.credits.importAssignmentFlow,
      component: () =>
        import('../pages/CreditsImport/CreditsImportStepper.vue'),
    },
    {
      ...creditsRoutesDictionary.credits.detail,
      component: () => import('../pages/OrderDetail/OrderDetail.vue'),
      props: true,
    },
  ],
};
