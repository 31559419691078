import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const creditsRoutesDictionary = moduleRouteDictionaryFactory(
  'credits',
  'credits',
  {
    overview: 'overview',
    standardAssignmentFlow: 'standard-assignment-flow',
    importAssignmentFlow: 'import-assignment-flow',
    chooseAssignMethod: 'assign/choose-assign-method',
    detail: 'detail/:id/:status',
  },
);
